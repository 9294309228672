import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { StaticQuery, graphql } from 'gatsby';

type Props = {
    links: {
        id: string;
        label: string;
        url: string;
        order: number;
    }[];
};

const LinksPage = (props: Props) => {
    const { links } = props;
    return (
        <Layout>
            <SEO title="Links | J Clarke Fitness" />
            <div className="links">
                {links.map(item => {
                    return (
                        <a className="link" key={item.id} href={item.url} target="_blank">
                            {item.label}
                        </a>
                    );
                })}
            </div>
            <style>{`
            .links {
                display: flex;
                flex-direction: column;
                max-width: 600px;
                padding: 0px 40px;
                margin: 40px auto;
            }
            .link {
                display: block;
                padding: 20px 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                border: 1px solid hsl(4, 90%, 58%);
                margin-bottom: 20px;
                border-radius: 60px;
                color: hsl(4, 90%, 58%);
                transition: all 200ms ease-in;
                font-weight: bold;
                text-align: center;
            }
            .link:last-child {
                margin-bottom: 0px;
            }
            .link:hover {
                background-color: hsl(4, 90%, 58%);
                color: white;
            }
        `}</style>
        </Layout>
    );
};

const linksQuery = graphql`
    query linksQuery {
        allMarkdownRemark(filter: { frontmatter: { collection: { eq: "links" } } }) {
            nodes {
                id
                frontmatter {
                    label
                    url
                    order
                }
            }
        }
    }
`;

export default () => (
    <StaticQuery
        query={linksQuery}
        render={data => {
            const { nodes } = data.allMarkdownRemark;
            const links = (nodes.map(item => ({
                id: item.id,
                label: item?.frontmatter?.label,
                url: item?.frontmatter?.url,
                order: item?.frontmatter?.order,
            })) as Props['links'])
                // sort links by order
                .sort((a, b) => Number(a.order) - Number(b.order));
            return <LinksPage links={links} />;
        }}
    />
);
